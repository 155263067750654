<template>
  <div>
    <guidePage v-if="versionCode == '10000111'"></guidePage>
    <div class="home" v-else>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="main">
            <div class="newsList" v-for="(item, index) in list" :key="index">
              <div
                class="newsList_center"
                v-if="!item.type"
                @click="goLanding(item.id)"
              >
                <div class="word">
                  <div class="title">{{ item.title }}</div>
                  <div class="newsInfo">
                    <div>
                      {{ item.channel }}
                    </div>
                    <div class="round"></div>
                    <div>
                      {{
                        $global.formatDate(
                          item.publishedTime,
                          "dd-MM-yyyy hh:mm"
                        )
                      }}
                    </div>
                  </div>
                </div>
                <div class="picurl">
                  <img :src="item.headImg" alt="" />
                </div>
              </div>

              <div class="adsItem" v-if="item.type && item.type === -1">
                <ins
                  class="adsbygoogle"
                  style="display: block"
                  data-ad-client="ca-pub-8908317842209223"
                  :data-ad-slot="isDrift == true ? '5709302264' : '5711487486'"
                  data-ad-format="auto"
                  data-full-width-responsive="true"
                ></ins>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <div class="loading" v-if="!requirDataAlready">
        <img src="@/assets/news/loading.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import '@/css/news/newsList.scss';
import '@/css/news/guidePage.scss'
import guidePage from './guidePage.vue'
// import MescrollVue from 'mescroll.js/mescroll.vue'
export default {
  name: "newsList",
  components: {
    // MescrollVue,
    guidePage,
  },
  data() {
    return {
      list: [],
      hasNext: true,
      mescroll: null,
      // page: 1,
      cid: 104,
      type: 'Gaming',
      isDrift: null,
      isinitialization: false,
      isData: false,
      requirDataAlready: true,
      versionCode: '',

      page: 0,
      artList: [],
      loading: false,
      finished: false,
      refreshing: false,
      // offset: "30",
      isLoading: false


    }
  },
  mounted() {
    this.isDrift = this.$store.state.isDrift
    this.versionCode = this.$store.state.versionCode
    console.log(this.$store.state.versionCode, '111111')
    this.$store.commit('UPDATE_CID', this.cid)

    this.$eventrack("show_h5/vpn", 'expose', {
      extensions: JSON.stringify({
        pve_cur: "/h5page/x/x/",
        cid: this.cid,
        type: this.type,
        isDrift: this.isDrift
      })
    });
    this.$eventrackFb("show_page_h5/vpn", 'expose', {
      extensions: JSON.stringify({
        pve_cur: "/h5page/x/x/",
        cid: this.cid,
        type: this.type,
        isDrift: this.isDrift
      }),
      pve_cur: "/h5page/vpn/x/x/",
      cid: this.cid,
      type: this.type,
      isDrift: this.isDrift
    });
    setTimeout(() => {
      window.addAds();
    }, 800)

  },
  methods: {
    getList(ispullup) {
      if (ispullup) {
        this.page = 1
      }
      console.log(this.finished, 'this.finished')
      if (this.finished === true) {
        return
      }
      return this.$api.getList({
        page: this.page,
        cid: this.cid,
        sdu: 2,
        size: 30
      }).then((res
      ) => {
        if (res.result_code === 200) {
          let data = res.data.source
          if (ispullup) {
            this.this = data
          } else {
            this.list.push(...data)
          }
          if (this.list.length > 8 && !this.list[2].type) {
            this.list.splice(2, 0, {
              type: -1
            })
          }
          if (this.list.length > 0) {
            this.isData = true
          }
          this.loading = false
          this.refreshing = false
          this.hasNext = res.data.hasNext
          this.finished = !res.data.hasNext

        } else {
          this.requirDataAlready = true
        }
        if (!this.isinitialization) {
          this.isinitialization = true
          this.$eventrack("show_h5/vpn", 'expose', {
            extensions: JSON.stringify({
              pve_cur: "/h5page/news/x/",
              type: this.type,
              cid: this.cid,
              state: res.result_code === 200 && res.data.source.length > 1 ? 1 : 0,
              isDrift: this.isDrift
            })
          });
          this.$eventrackFb("show_pageNews_h5/vpn", 'expose', {
            extensions: JSON.stringify({
              pve_cur: "/h5page/news/x/",
              type: this.type,
              cid: this.cid,
              state: res.result_code === 200 && res.data.source.length > 1 ? 1 : 0,
              isDrift: this.isDrift
            }),
            pve_cur: "/h5page/news/x/",
            type: this.type,
            cid: this.cid,
            state: res.result_code === 200 && res.data.source.length > 1 ? 1 : 0,
            isDrift: this.isDrift
          });
        }
      }).catch(() => {
        this.loading = false
        this.refreshing = false
        this.finished = false
        this.requirDataAlready = true
        this.isinitialization = true
        this.$eventrack("show_h5/vpn", 'expose', {
          extensions: JSON.stringify({
            pve_cur: "/h5page/news/x/",
            type: this.type,
            cid: this.cid,
            state: -1,
            isDrift: this.isDrift
          })
        });
        this.$eventrackFb("show_pageNews_h5/vpn", 'expose', {
          extensions: JSON.stringify({
            pve_cur: "/h5page/news/x/",
            type: this.type,
            cid: this.cid,
            state: -1,
            isDrift: this.isDrift
          }),
          pve_cur: "/h5page/news/x/",
          type: this.type,
          cid: this.cid,
          state: -1,
          isDrift: this.isDrift
        });
      });
    },
    goLanding(id) {
      this.$eventrack("clicknews_h5/vpn", 'click', {
        extensions: JSON.stringify({
          id: id,
          cid: this.cid,
          isDrift: this.isDrift
        })
      });
      this.$eventrackFb("clicknews_h5/vpn", 'click', {
        extensions: JSON.stringify({
          id: id,
          cid: this.cid,
          isDrift: this.isDrift
        }),
        id: id,
        cid: this.cid,
        isDrift: this.isDrift
      });
      this.$router.push({ name: 'landing', query: { id: id } })

    },
    onLoad() {
      console.log(1111, 'onLoad')
      if (!this.finished) {
        this.page = this.page + 1
        if (this.hasNext) {

          this.getList();
        }

      } else {
        this.finished = false
      }
    },
    onRefresh() {
      console.log('onRefresh')
      this.finished = false;
      this.loading = false;
      this.getList(true);
      setTimeout(() => {
        window.addAds();
      }, 1000)
    },
  },
};
</script>