<!-- GuidePage.vue -->
<template>
  <div class="guidePage">
    <div class="title">Welcome!</div>
    <div class="img">
      <img src="../../../assets/vpn/vpn1.png" alt="" />
    </div>
    <div class="title1">1,Select service node</div>
    <div class="img">
      <img src="../../../assets/vpn/vpn2.png" alt="" />
    </div>
    <div class="title1">2,Click the connect button</div>
  </div>
</template>

<script>
import '@/css/news/guidePage.scss';

export default {
  name: "GuidePage",
};
</script>
